<template>
  <div>
    <div class="reserved-area-top-box-wrapper">
      <div class="container">
        <div class="reserved-area-top-box d-flex justify-content-center align-items-center flex-column">
          <p class="reserved-area-top-title">Olá {{ this.$root.userInfo.name }},</p>
          <p class="reserved-area-top-subtitle"><span>{{ this.$root.userInfo.credits }}</span> Créditos Disponíveis</p>
        </div>
      </div>
    </div>
    <div class="reserved-area-page">
      <div class="container">
        <div class="d-xl-flex">
          <div class="reserved-area-page-column">
            <reservedAreaMenu/>
          </div>
          <div class="reserved-area-page-column">
            <div class="reserved-area-credits-wrapper">
              <FormulateForm class="reserved-area-credits-form" @submit="buyCredits">
                <div class="reserved-area-credits-accordion">
                  <div class="reserved-area-credits-accordion-item active">
                    <button type="button" class="reserved-area-credits-accordion-item-btn d-flex justify-content-between align-items-center" @click="toggleAccordion">
                      <p>Comprar Créditos</p>
                      <div class="reserved-area-credits-accordion-item-btn-icon">
                        <chevronDown/>
                      </div>
                    </button>
                    <div class="reserved-area-credits-accordion-item-body-wrapper">
                      <div class="reserved-area-credits-accordion-item-body">
                        <div class="credits-form">
                          <div class="credits-form-item d-md-flex justify-content-between align-items-center">
                            <p class="credits-form-title">Créditos</p>
                            <div class="credits-form-input d-flex justify-content-center">
                              <button type="button" v-if="creditsValue > 10" class="credits-form-input-btn credits-form-input-less d-flex justify-content-center align-items-center" @click="removeCredits">
                                <minus/>
                              </button>
                              <input type="text" v-model="creditsValue" disabled>
                              <button type="button" class="credits-form-input-btn credits-form-input-more d-flex justify-content-center align-items-center" @click="addCredits">
                                <plus/>
                              </button>
                            </div>
                            <p class="credits-form-subtitle">Valor {{ price }}€</p>
                          </div>
                        </div>
                        <!--<div class="add-voucher-wrapper">
                          <button class="add-voucher-btn d-flex justify-content-start align-items-center" @click="openVoucherForm">
                            <p>Adicionar Voucher Promocional</p>
                            <div class="add-voucher-btn-icon">
                              <plus/>
                            </div>
                          </button>
                          <form class="add-voucher-form">
                            <div class="d-flex justify-content-start align-items-center">
                              <div class="add-voucher-input">
                                <input type="text">
                              </div>
                              <button type="button" class="generic-btn red-gradient smaller">Validar</button>
                            </div>
                          </form>
                        </div>-->
                      </div>
                    </div>
                  </div>
                  <div class="reserved-area-credits-accordion-item">
                    <button type="button" class="reserved-area-credits-accordion-item-btn d-flex justify-content-between align-items-center" @click="toggleAccordion">
                      <p>Método de Pagamento</p>
                      <div class="reserved-area-credits-accordion-item-btn-icon">
                        <chevronDown/>
                      </div>
                    </button>
                    <div class="reserved-area-credits-accordion-item-body-wrapper">
                      <div class="reserved-area-credits-accordion-item-body">
                        <div class="auth-faqs-radio-wrapper">
                          <label>
                            <input type="radio" name="payment-method" value="mb" v-model="paymentMethod">
                            <div class="auth-faqs-radio-btn d-flex justify-content-lg-between align-items-center">
                              <div class="flex-1 d-flex justify-content-start align-items-center">
                                <div class="auth-faqs-radio-btn-image d-flex justify-content-center align-items-center">
                                  <img src="@/assets/img/multibanco-logo.png" alt="">
                                </div>
                                <p class="auth-faqs-radio-btn-title">Multibanco</p>
                              </div>
                              <div class="auth-faqs-radio-checkbox"></div>
                            </div>
                          </label>
                        </div>
                        <div class="auth-faqs-radio-wrapper">
                          <label>
                            <input type="radio" name="payment-method" value="cc" v-model="paymentMethod">
                            <div class="auth-faqs-radio-btn d-flex justify-content-lg-between align-items-center">
                              <div class="flex-1 d-flex justify-content-start align-items-center">
                                <div class="auth-faqs-radio-btn-image d-flex justify-content-center align-items-center">
                                  <img src="@/assets/img/visa.png" alt="">
                                </div>
                                <p class="auth-faqs-radio-btn-title">Visa</p>
                              </div>
                              <div class="auth-faqs-radio-checkbox"></div>
                            </div>
                          </label>
                        </div>
                        <div class="auth-faqs-radio-wrapper">
                          <label>
                            <input type="radio" name="payment-method" value="mw" v-model="paymentMethod">
                            <div class="auth-faqs-radio-btn d-flex justify-content-lg-between align-items-center">
                              <div class="flex-1 d-flex justify-content-start align-items-center">
                                <div class="auth-faqs-radio-btn-image d-flex justify-content-center align-items-center">
                                  <img src="@/assets/img/mb-way.png" alt="">
                                </div>
                                <p class="auth-faqs-radio-btn-title">MB Way</p>
                              </div>
                              <div class="auth-faqs-radio-checkbox"></div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="reserved-area-credits-accordion-item">
                    <button type="button" class="reserved-area-credits-accordion-item-btn d-flex justify-content-between align-items-center" @click="toggleAccordion">
                      <p>Dados de Faturação</p>
                      <div class="reserved-area-credits-accordion-item-btn-icon">
                        <chevronDown/>
                      </div>
                    </button>
                    <div class="reserved-area-credits-accordion-item-body-wrapper">
                      <div class="reserved-area-credits-accordion-item-body">
                        <div class="box-form-group">
                          <div class="row box-form-row">
                            <div class="col-12 col-lg-6 box-form-column">
                              <div class="form-field">
                                <FormulateInput type="text" name="name" validation="required" :errors="errors" v-model="firstName" placeholder="Insira o seu primeiro nome" label="Primeiro Nome" />
                              </div>
                            </div>
                            <div class="col-12 col-lg-6 box-form-column">
                              <div class="form-field">
                                <FormulateInput type="text" name="last_name" validation="required" v-model="lastName" placeholder="Insira o seu último nome" label="Último Nome" />
                              </div>
                            </div>
                            <div class="col-12 box-form-column">
                              <div class="form-field">
                                <FormulateInput type="text" name="address" validation="required" v-model="billingAddress" placeholder="Insira a sua morada" label="Morada" />
                              </div>
                            </div>
                            <div class="col-12 col-lg-6 box-form-column">
                              <div class="form-field">
                                <FormulateInput type="text" name="zipCode" validation="required" v-model="zipCode" placeholder="0000-000" label="Código-Postal" />
                              </div>
                            </div>
                            <div class="col-12 col-lg-6 box-form-column">
                              <div class="form-field">
                                <FormulateInput type="text" name="city" validation="required" v-model="city" placeholder="Insira a sua cidade" label="Cidade" />
                              </div>
                            </div>
                            <div class="col-12 col-lg-6 box-form-column">
                              <div class="form-field">
                                <FormulateInput type="select" name="country" validation="required" v-model="country" placeholder="Selecione o seu país" label="País" :options="this.$root.countries" />
                              </div>
                            </div>
                            <div class="col-12 col-lg-6 box-form-column">
                              <div class="form-field">
                                <FormulateInput type="text" name="vat" validation="required" v-model="nif" placeholder="Insira o seu NIF" label="NIF" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
                  <button type="submit" class="reserved-area-form-btn generic-btn grey-gradient">Comprar Créditos</button>
                </div>
              </FormulateForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import chevronDown from "@/components/svg/chevron-down.vue";
import plus from "@/components/svg/plus.vue";
import minus from "@/components/svg/minus.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "credits",
  data() {
    return {
      errors: [],
      showMessage: false,
      formResponseMessage: '',
      creditsValue: 10,
      price: 1,
      paymentMethod: '',
      firstName: '',
      lastName: '',
      billingAddress: '',
      zipCode: '',
      city: '',
      country: '',
      nif: '',
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.definicoes,
    }
  },
  components: {
    reservedAreaMenu,
    chevronDown,
    plus,
    minus
  },
  methods: {
    toggleAccordion (e) {
      let clickedItem = e.currentTarget.closest('.reserved-area-credits-accordion-item');
      let items = Array.from(document.querySelectorAll('.reserved-area-credits-accordion-item'));

      if ( clickedItem.classList.contains('active') ) {
        clickedItem.classList.remove('active');
      } else {
        items.forEach(node => {
          node.classList.remove('active');
        });

        clickedItem.classList.add('active');
      }
    },
    openVoucherForm: function(e) {
      e.preventDefault();
      let wrapper = document.querySelector('.add-voucher-wrapper');

      if ( wrapper.classList.contains('active') ) {
        wrapper.classList.remove('active');
      } else {
        wrapper.classList.add('active');
      }
    },
    addCredits: function () {
      this.creditsValue = this.creditsValue + 10;
      this.price = this.price + 1;
    },
    removeCredits: function () {
      if ( this.creditsValue >= 20 ) {
        this.creditsValue = this.creditsValue - 10;
      }

      if ( this.price >= 2 ) {
        this.price = this.price - 1;
      }
    },
    buyCredits: function() {
      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        credits_count: this.creditsValue,
        payment_method: this.paymentMethod,
        firstName: this.firstName,
        lastName: this.lastName,
        address: this.billingAddress,
        zipCode: this.zipCode,
        city: this.city,
        country: this.country,
        nif: this.nif,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'order/credits', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {
              window.location.href = response.data.model.data.REDIRECTURL;
            }
          })
          .catch((e) => {
            if (e.response.data.errors) {
              this.errors = e.response.data.errors;
            } else {
              this.showMessage = true;
              this.formResponseMessage = 'An error occurred.';
            }
          });
    },
    setData() {
      if (Object.keys(this.$root.userInfo.billing_info).length) {
        this.firstName = this.$root.userInfo.billing_info.name;
        this.lastName = this.$root.userInfo.billing_info.last_name;
        this.country = this.$root.userInfo.billing_info.country_id;
        this.city = this.$root.userInfo.billing_info.city;
        this.phone = this.$root.userInfo.billing_info.phone;
        this.zipCode = this.$root.userInfo.billing_info.postal_code;
        this.billingAddress = this.$root.userInfo.billing_info.address;
        this.nif = this.$root.userInfo.billing_info.vat;
      }
    }
  },
  async created() {
    await this.$root.getCustomer();

    this.setData();
    this.$root.pageTitle = this.$root.translations.definicoes;
  }
}
</script>